import dayjs from 'dayjs';
import toast from 'react-hot-toast';
// Styles
import { makeStyles } from '@material-ui/styles';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

// MUI components
import { Button, Divider, Typography } from '@mui/material';

import { formatOnlyDate } from 'src/utils/date-utils';

import { RootState, AppDispatch } from 'src/store/store';
import FilterComponent from 'src/layouts/common/filter-search';
// Store slices (actions)
import { deleteBid, fetchBids, fetchBidsSum } from 'src/store/slices/bid-slice';

import ReactTable from 'src/components/table/material-table';

import BidModal from 'src/sections/components/modals/operations/bidder-modal';
import DeleteBidModal from 'src/sections/components/modals/operations/delete-bid';

const AllBids: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const classes = useStyles();

    const today = dayjs().format('YYYY-MM-DD');

    // states
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [totalWeight, setTotalWeight] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContext, setModalContext] = useState<'add' | 'edit'>('add');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    // Redux selectors
    const { user } = useSelector((state: RootState) => state.user);
    const { records: bids, totalRecords, loading } = useSelector((state: any) => state.bids);

    const userRole = user?.role;

    // Fetch bids and total weight
    const fetchTransactions = useCallback(async () => {
        try {
            // Fetch bids
            await dispatch(
                fetchBids({
                    pageSize,
                    page: page + 1,
                    searchText: searchQuery,
                    startDate,
                    endDate,
                })
            ).unwrap();

            // Fetch total weight
            const response = await dispatch(fetchBidsSum({ startDate, endDate })).unwrap();

            // Extract weight from _sum and set it
            setTotalWeight(response?._sum?.weight || 0);
        } catch (error) {
            toast.error('Error fetching data');
        }
    }, [dispatch, page, pageSize, searchQuery, startDate, endDate]);

    useEffect(() => {
        fetchTransactions();
    }, [fetchTransactions]);

    // Recalculate total weight when bids change
    useEffect(() => {
        const updatedTotalWeight = bids.reduce((sum: number, bid: { weight?: number }) => sum + (bid.weight || 0), 0);
        setTotalWeight(updatedTotalWeight);
    }, [bids]);    

    // --------------------------------- handler functions -------------------------------
    const handleAddOrUpdateBid = (updatedBid: { id: string; weight: number }) => {
        const updatedBids = bids.map((bid: { id: string; weight?: number }) =>
            bid.id === updatedBid.id ? { ...bid, weight: updatedBid.weight } : bid
        );
        const updatedTotalWeight = updatedBids.reduce((sum: number, bid: { weight?: number }) => sum + (bid.weight || 0), 0);
        setTotalWeight(updatedTotalWeight);
    };    

    const handleChangePage = useCallback(
        (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setPage(newPage);
        },
        []
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setPageSize(parseInt(event.target.value, 10));
            setPage(0);
        },
        []
    );

    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    const handleDateChange = (startDateVal: string, endDateVal: string) => {
        setStartDate(startDateVal);
        setEndDate(endDateVal);
    };

    const handleDeleteClick = (row: any) => {
        setSelectedRow(row);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (selectedRow) {
            dispatch(deleteBid(selectedRow.id));
            toast.success("Bid Deleted Successfully");
            setIsDeleteModalOpen(false);
            setSelectedRow(null);
        }
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedRow(null);
    };

    const handleEditClick = (row: any) => {
        setSelectedRow(row);
        setModalContext('edit');
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedRow(null);
        setIsModalOpen(false);
    };

    // --------------------------------- end of handler functions -------------------------------

    const headers = useMemo(() => [
        { accessorKey: 'bid_date', header: 'Bid Date' },
        { accessorKey: 'bidder_id', header: 'Bidder Name-Code' },
        { accessorKey: 'market_rate', header: 'Market Rate (₹)' },
        {
            accessorKey: 'weight',
            header: 'Weight',
            // Footer: () => (
            //     <Typography align="center" fontWeight="600" variant="body2">
            //         Total: {totalWeight.toFixed(2)} kg
            //     </Typography>
            // ),
        },
        {
            accessorKey: 'actions',
            header: 'Actions',
            enableSorting: false,
            Cell: ({ row }: any) => {
                const bidDate = dayjs(row.original?.bid_date).format('YYYY-MM-DD');
                const isToday = bidDate === today;
    
                return isToday ? (
                    <div className={classes.actionButtonContainer}>
                        <Button
                            variant="outlined"
                            className={classes.editButton}
                            onClick={() => handleEditClick(row.original)}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="outlined"
                            className={classes.deleteButton}
                            onClick={() => handleDeleteClick(row.original)}
                        >
                            Delete
                        </Button>
                    </div>
                ) : null;
            },
        },
    ], [today, totalWeight]);

    const filteredHeaders = useMemo(
        () =>
            userRole === 'MELTING_TEAM'
                ? headers.filter(header => header.accessorKey !== 'actions')
                : headers,
        [userRole, headers]
    );    

    // Table Rows
    const rows = useMemo(() =>
        (bids || []).map((bid: any) => ({
            id: bid?.id,
            bid_date: bid?.created_at ? formatOnlyDate(bid.bid_date) : 'N/A',
            bidder_id: bid?.bidder?.name || 'Unknown',
            market_rate: bid?.market_rate ? `₹ ${bid.market_rate}` : '₹0',
            weight: bid?.weight || 0,
            bidder: bid?.bidder,
        }))
    , [bids]);    

    return (
        <div>
            <FilterComponent
                onDateChange={handleDateChange}
                onSearch={handleSearch}
                searchResultCount={totalRecords}
                disableTransactionType
                dayRange={1}
                searchPlaceholder='Search'
            />
            <ReactTable
                headers={filteredHeaders}
                enableStickyHeader
                rows={rows}
                totalRecords={totalRecords}
                page={page}
                pageSize={pageSize}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                loading={loading}
                enableSorting
            />
            <Divider />
            {isModalOpen && (
                <BidModal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    rowDetails={selectedRow}
                    context={modalContext}
                    onSave={handleAddOrUpdateBid}
                />
            )}
            <DeleteBidModal
                open={isDeleteModalOpen}
                onClose={handleCloseDeleteModal}
                onDelete={handleConfirmDelete}
                bidDetails={selectedRow}
            />
        </div>
    );
};

const useStyles = makeStyles({
    actionButtonContainer: {
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editButton: {
        borderColor: '#919EAB52',
    },
    deleteButton: {
        color: 'red',
        borderColor: 'red',
    },
});

export default AllBids;
