// React
import React from 'react';

// MUI components
import { Card } from '@mui/material';

// Components
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';

import MeltingReport from 'src/sections/components/tables/melting/melting-report';

const RenderHeader = ({ onAdd }: { onAdd: () => void }) => (
  <CustomBreadcrumbs
    heading="Gold Processing Report"
    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Melting Report' }]}
    sx={{ mb: { xs: 3 } }}
  />
);

const AuditReport: React.FC = () => (
    <div>
      <RenderHeader onAdd={() => {}} />
      <Card sx={{ p: 1, pt: 5 }}>
        <MeltingReport />
      </Card>
    </div>
  );

export default AuditReport;
