/* eslint-disable */
// @ts-nocheck

import React from 'react';
import { format } from 'date-fns';
import { useController, useFormContext } from 'react-hook-form';

import { DatePicker } from '@mui/x-date-pickers';
import { TextField, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type Props = TextFieldProps & {
  name: string;
  label: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onSelect?: (date: Date) => void;
};

const RHFDatePicker: React.FC<Props> = ({
  name,
  label,
  helperText,
  fullWidth,
  sx,
  minDate,
  maxDate,
  disabled,
  onSelect,
  ...other
}) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange, ...restField },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleDateChange = (date: Date | null) => {
    if (date) {
      onChange(date.toISOString()); // Converts to '2024-12-24T06:46:03.959Z'
      if (onSelect) {
        onSelect(date);
      }
    } else {
      onChange(null);
    }
  };  

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        localeText={{
          fieldDayPlaceholder: () => 'DD',
          fieldMonthPlaceholder: () => 'MMM',
          fieldYearPlaceholder: () => 'YYYY',
        }}
        format="dd-MMM-yyyy"
        disabled={disabled}
        value={value ? new Date(value) : null}
        sx={{ width: '100%' }}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            fullWidth: fullWidth,
            variant: 'outlined',
            error: !!error,
            helperText: error ? error?.message : helperText,
            readOnly: true,
          },
        }}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            {...restField}
            error={!!error}
            helperText={error ? error.message : helperText}
            fullWidth
            {...other}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default RHFDatePicker;
