import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getOrdinalSuffix = (day: number): string =>
  day > 3 && day < 21 ? 'th' : ['th', 'st', 'nd', 'rd'][day % 10] || 'th';

export const formatDate = (date: string): string => {
  const parsedDate = dayjs(date);
  return ` ${parsedDate.format('DD-MMM-YYYY, HH:mm')}`;
};

export const formatOnlyDate = (date: string): string => {
  const parsedDate = dayjs(date);
  return ` ${parsedDate.format('DD-MMM-YYYY')}`;
};

