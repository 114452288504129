import { MeltingInterface } from 'src/interfaces';

import API from '..';
import { melting } from '../entpoints';

export const getMeltingCount = async () => {
  try {
    const response = await API.get(melting.getMeltingCount);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getMeltingRecords = async (
  status: any,
  pageSize: number,
  pageNumber: number,
  startDate?: string,
  endDate?: string,
  searchText?: string
) => {
  try {
    const response = await API.get(
      `${melting.getMelting}?status=${status}&page_size=${pageSize}&page=${pageNumber}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateMeltingRecord = async (data: MeltingInterface) => {
  try {
    const response = await API.patch(`${melting.updateMelting}/${data.id}`, data);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getMeltingReports = async (
  startDate: string,
  endDate: string,
  status: string = ' '
) => {
  try {
    const response = await API.get(
      `${melting.getMeltingSum}?status=${status}&start_date=${startDate}&end_date=${endDate}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

// --------------------------------- Melting Report (AUdit)--------------------------------------------------------
export const getMeltingAudit = async (startDate: string, endDate: string, pageSize: number, pageNumber: number,  searchText?: string) => {
  try {
    const response = await API.get(
      `${melting.getMeltingAudits}?start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page=${pageNumber}&search_text=${searchText}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getMeltingAuditSum = async (startDate: string, endDate: string) => {
  try {
    const response = await API.get(
      `${melting.getMeltingAuditReportSum}?start_date=${startDate}&end_date=${endDate}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

// --------------------------------- Gold Collection Report --------------------------------------------------------
export const getGoldCollectionReport = async (startDate: string, endDate: string, pageSize: number, pageNumber: number, searchText?: string) => {
  try {
    const response = await API.get(
      `${melting.getCollectionReports}?start_date=${startDate}&end_date=${endDate}&page_size=${pageSize}&page=${pageNumber}&search_text=${searchText}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

export const getMeltingSummary = async (
  pageSize: number,
  pageNumber: number,
  startDate?: string,
  endDate?: string,
  searchText?: string
) => {
  try {
    const url = `${melting.getMeltingSummary}/?page_size=${pageSize}&page=${pageNumber}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}`;

    const response = await API.get(url);
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message);
  }
};

export const getMeltingSummaryById = async (id: string) => {
  try {
    const response = await API.get(`${melting.getMeltingSummary}/${id}`);
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message);
  }
};
export const upadteMeltingSummaryById = async (id: string, data: any) => {
  try {
    const response = await API.patch(`${melting.getMeltingSummary}/${id}`, data);
    return response;
  } catch (error: any) {
    throw new Error(error.response?.data?.message);
  }
};
